import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Paragraph } from '@twilio-paste/paragraph';
import { Button } from '@twilio-paste/button';
import Changelog from '@twilio-paste/menu-primitive/CHANGELOG.md';
import { useMenuPrimitiveState, MenuPrimitive, MenuPrimitiveItem, MenuPrimitiveButton, MenuPrimitiveButtonProps, MenuPrimitiveSeparator } from '@twilio-paste/menu-primitive';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { defaultExample, subMenuExample, customExample } from '../../../component-examples/MenuPrimitiveExamples';
export const pageQuery = graphql`
  {
    allPastePrimitive(filter: {name: {eq: "@twilio-paste/menu-primitive"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    menuBarExample: file(
      sourceInstanceName: {eq: "assets"}
      relativePath: {eq: "images/menu-primitive-images/menubar.png"}
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mdx(fields: {slug: {eq: "/primitives/menu-primitive/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Menu Primitive" categoryRoute={SidebarCategoryRoutes.PRIMITIVES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/primitives/menu" storybookUrl="/?path=/story/primitives-menu--simple-menu" data={props.data.allPastePrimitive.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Menu Primitive`}</h3>
        <p>{`The menu primitive is an unstyled functional version of a menu. It can be used to build a component following the WAI-ARIA Menu pattern. Our `}<a parentName="p" {...{
            "href": "/components/menu"
          }}>{`Menu`}</a>{` is built on top of this primitive.`}</p>
        <p>{`The purpose of providing these unstyled primitives is to cater for instances when the styled Menu provided by Paste doesn’t meet the requirements needed to solve a unique or individual customer problem. At that point you are welcome to fallback to this functional primitive to roll your own styled menu while still providing a functional and accessible experience to your customers. However, we strongly recommend reviewing your design proposal with the Design Systems team before doing so.`}</p>
        <p>{`This primitive should be used to compose all custom menus to ensure accessibility and upgrade paths.`}</p>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle as="h3" mdxType="CalloutTitle">Before you roll your own menus...</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    We strongly suggest that all components built on top of this primitive get reviewed by the Design Systems team and
    go through the UX Review process to ensure an excellent experience for our customers.
  </CalloutText>
        </Callout>
        <h2>{`Examples`}</h2>
        <h3>{`Basic menu`}</h3>
        <LivePreview scope={{
          useMenuPrimitiveState,
          MenuPrimitiveButton,
          MenuPrimitive,
          MenuPrimitiveItem,
          MenuPrimitiveSeparator
        }} noInline mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h3>{`Sub menu`}</h3>
        <LivePreview scope={{
          useMenuPrimitiveState,
          MenuPrimitiveButton,
          MenuPrimitive,
          MenuPrimitiveItem,
          MenuPrimitiveSeparator
        }} noInline mdxType="LivePreview">
  {subMenuExample}
        </LivePreview>
        <h3>{`Custom styling`}</h3>
        <p>{`You can provide custom styling to the primitive menu by utilizing the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop on each component.`}</p>
        <p>{`The menu primitive does not come with any styling, and thus you could mix the functionality of it with another component by using the `}<em parentName="p">{`as`}</em>{` prop. By doing so, you can get styling from another component, and menu functionality from this primitive.`}</p>
        <p>{`Because these are not styled, rendering any of them `}<em parentName="p">{`as`}</em>{` another component you can mix the functionality of two components together. Styling from one, menu functionlity from the primitive component.`}</p>
        <p>{`In the example below, we import the Paste Button `}<inlineCode parentName="p">{`import {Button} from '@twilio-paste/button';`}</inlineCode>{` and use it as the Menu Button via the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop. We also use `}<inlineCode parentName="p">{`Box`}</inlineCode>{` from Paste to style the menu and menu items.`}</p>
        <LivePreview scope={{
          useMenuPrimitiveState,
          MenuPrimitiveButton,
          MenuPrimitive,
          MenuPrimitiveItem,
          MenuPrimitiveSeparator,
          Button,
          Box
        }} noInline mdxType="LivePreview">
  {customExample}
        </LivePreview>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <p>{`This package is a wrapper around the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/menu/"
          }}>{`Reakit Menu`}</a>{`. If you’re wondering why we wrapped that package into our own, we reasoned that it would be best for our consumers’ developer experience. For example:`}</p>
        <ul>
          <li parentName="ul">{`If we want to migrate the underlying nuts and bolts in the future, Twilio products that depend on this primitive would need to replace all occurrences of `}<inlineCode parentName="li">{`import … from ‘x-package’`}</inlineCode>{` to `}<inlineCode parentName="li">{`import … from ‘@some-new/package’`}</inlineCode>{`. By wrapping it in `}<inlineCode parentName="li">{`@twilio-paste/x-primitive`}</inlineCode>{`, this refactor can be avoided. The only change would be a version bump in the ‘package.json\` file for the primitive.`}</li>
          <li parentName="ul">{`We can more strictly enforce semver and backwards compatibility than some of our dependencies.`}</li>
          <li parentName="ul">{`We can control when to provide an update and which versions we allow, to help reduce potential bugs our consumers may face.`}</li>
          <li parentName="ul">{`We can control which APIs we expose. For example, we may chose to enable or disable usage of certain undocumented APIs.`}</li>
        </ul>
        <Callout mdxType="Callout">
  <CalloutTitle as="h3" mdxType="CalloutTitle">We've chosen not to include MenuBar for now</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Reakit includes a menubar which we are not exposing. The reason being is that a menubar is directly aligned to the
    desktop software menubar you see at the top of your screen. These have limited use cases on the web unless you're
    building a desktop replacement like Figma or Google Docs. We may reconsider this in the future if we see a good use
    for this.
    <Img fluid={props.data.menuBarExample.childImageSharp.fluid} mdxType="Img" />
  </CalloutText>
        </Callout>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/menu-primitve - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Props`}</h4>
        <p>{`This props list is a scoped version of the properties available from the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/menu/"
          }}>{`Reakit Menu`}</a>{` package. For a full list, visit the `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/menu/"
          }}>{`Reakit`}</a>{`.`}</p>
        <h5>{`useMenuPrimitiveState`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`baseId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ID that will serve as a base for all the items IDs.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`rtl`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`orientation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`horizontal, vertical, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`currentId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, null, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The current focused item `}<inlineCode parentName="td">{`id`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`loop`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, horizontal, vertical`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`wrap`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, horizontal, vertical`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`visible`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether it's visible or not.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`animated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number, boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`placement`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`auto-start, auto, auto-end, top-start, top, top-end, right-start, right, right-end, bottom-end, bottom, bottom-start, left-end, left, left-start`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`gutter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Offset between the reference and the popover on the main axis. Should not be combined with `}<inlineCode parentName="td">{`unstable_offset`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuPrimitive`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`hideOnClickOutside`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When enabled, user can hide the dialog by clicking outside it.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuPrimitiveArrow`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuPrimitiveButton`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuPrimitiveDisclosure`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuPrimitiveGroup`}</h5>
        <p>{`No props to show`}</p>
        <h5>{`MenuPrimitiveItem`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onClick`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`() => void`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`href`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuPrimitiveItemCheckbox`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`value`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox's value is going to be used when multiple checkboxes share the same state. Checking a checkbox with value will add it to the state array.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`checked`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox's checked state. If present, it's used instead of `}<inlineCode parentName="td">{`state`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MenuItemCheckbox's name as in `}<inlineCode parentName="td">{`menu.values`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuPrimitiveItemRadio`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`value`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox's value is going to be used when multiple checkboxes share the same state. Checking a checkbox with value will add it to the state array.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`checked`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox's checked state. If present, it's used instead of `}<inlineCode parentName="td">{`state`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MenuItemRadio's name as in `}<inlineCode parentName="td">{`menu.values`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      